@import "../../assets/styles/variables";

@mixin filterMenu {
  ul.ant-menu-root {
    background-color: $main-background;

    li {
      border-top: 1px solid rgba(122,69,33, .15);
      border-bottom: 1px solid rgba(122,69,33, .15);
      border-right: none;
      border-left: none;

      margin: 0;
      text-transform: uppercase;
      color: $text-accent-color;

      &.main-option {
        .ant-menu-submenu-title {
          font-size: 1.1rem;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .ant-menu-submenu {
      &.ant-menu-submenu-open {
        border-color: $active-border;
        background-color: $active-background;

        .ant-menu-submenu-title {
          background-color: $active-title-background;
          font-weight: 700;
        
        }
      }

      &.ant-menu-submenu-active {
        .ant-menu-submenu-title {
          color: $text-accent-color;

          .ant-menu-submenu-arrow:before,
          .ant-menu-submenu-arrow:after {
            background-color: $text-accent-color;
          }
        }
      }

      .ant-menu-submenu-title {
        margin: 0;
        height: 3.5rem;
        line-height: 3.5rem;
        font-size:  1rem !important;
        color: #634f35 !important;

        &:hover {
          color: $text-accent-color;

          .ant-menu-submenu-arrow:before,
          .ant-menu-submenu-arrow:after {
            background: $text-accent-color;
          }
        }
      }

      .ant-menu-sub {
      
        background: transparent;
      }

      li.ant-menu-item {
        border-color: $active-border;
      }
    }

    li.ant-menu-item {
      height: 3.5rem;
      line-height: 3.5rem;
      font-size: 0.85rem;

      &:hover:after,
      &.ant-menu-item:after,
      &:after {
        border-width: 0;
      }

      &.ant-menu-item-selected {
        background-color: #f4d9b3;
      }
    }
  }
}

// .product-container {
//   zoom: 85%;
// }
.ant-layout-has-sider {
  height: 100%;

  .ant-layout-sider {
    background-color: $main-background;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      border-right: 1px solid $main-border;

      .sidebar-header {
        text-align: center;
        padding: 1rem 0;
        border-bottom: 1px solid $main-border;
        z-index: 1;

        h2 {
          font-weight: 700;
          color: $text-accent-color;
          margin: 0;
        }
      }

      .sidebar-content {
        flex: 1;
        margin-top: -1px;
        overflow-y: auto;
        overflow-x: hidden;
        font-family: "Open Sans";

        .filter-by-title {
          padding: 1rem;
          

          h3 {
            font-family: 'Open Sans';
            font-size: 1rem;
            font-weight: 800;
            color: #634f35;
            
            margin: 0;
          }
        }
       

        @include filterMenu;
      }
    }
  }
}

.product-catalog-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: "Open Sans";

  .leader-board {
    .slide {
      min-height: 220px;
      position: relative;

      .slide-banner {
        height: 220px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }

      .slide-content {
        position: absolute;
        z-index: 1;

        &.left-bottom {
          left: 50px;
          bottom: 1rem;
        }
      }
    }

    .slick-dots {
      right: 100px;
      width: auto;
      bottom: 2rem;

      button {
        border: 1px solid white;
        border-radius: 50%;

        &:before {
          color: white;
          line-height: 22px;
          font-size: 0.6rem;
        }
      }
    }
  }

  .product-query {
    .search-container {
      .ant-input {
        font-family: "Montserrat";
        color: #777777;
        border: 1px solid #B2B2B2;
      }
    }

    padding: 16px 50px;

    .product-query-form {
      .ant-form-item {
        display: flex;

        .ant-form-item-control-wrapper {
          flex: 1;
        }
      }
    
    }

    .product-query-result-text {
      color: #666666;
      font-size: 1.2rem;
      font-weight: 700;
      text-align: center;

      span {
        font-weight: 100;
      }
    }

    .product-loading {
      display: flex;
      justify-content: center;
    }
  }

  .product-result {
    flex: 1;
    padding: 16px 50px;
    // overflow: auto;
    // overflow-x: hidden;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    // max-height: 800px;

    .ant-card {
      flex: 1 0 250px;
      max-width: 250px;
      // max-height: 250px;
      margin: 8px 0;
      text-align: center;
      border-radius: 10px;

      .product-thumb {
        .image-holder {
          display: inline-block;
          width: 100%;
          max-width: 200px;
          height: 150px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
        .image-loader {
          display: none;
        }

        .slick-dots {
          position: static;

          li {
            margin: 0;

            button {
              &:before {
                color: $text-primary-color-active;
                font-size: 0.6rem;
              }
            }
          }
        }
      }

      p,
      h2 {
        margin: 0;
        text-transform: uppercase;
        color: #666666;
      }

      h2 {
        font-weight: 700;
        font-size: 1.1rem;
      }

      &.ant-card-hoverable {
        cursor: default;
      }

      &:hover {
        p,
        h2 {
          color: $text-primary-color-active;
        }
      }
    }

    
    
  }

}

.configs,.flavors, .description {
 h2 {
   font-family: 'Open Sans';
   font-size: 1.125rem;
   font-weight: 600;
   color: #7f7f7f;
 }
}

@media (max-width: 600px) {
  .ant-modal.product-details-modal {
    button.ant-modal-close svg {
      color: #000000 !important;
    }
    .ant-modal-header {
      padding: 20px 24px;
      .ant-modal-title {
        font-family: "Open Sans";
        font-size: 1rem;
        font-weight: 600;
        color: #7f7f7f;
      }
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;
        height: 25px;
        background: center / cover no-repeat
          url("../../assets/images/red-bar.png");
      }
    }
    .product-details-content {
      h2 {
        font-family: "Open Sans";
        font-size: 1rem;
        font-weight: 600;
      }
      .product-gallery {
        padding-bottom: 5px !important;
        h2 {
          color: #323232;
          text-transform: capitalize;
        }
      }
      .product-details {
        .configs,
        .flavors,
        .description {
          h2 {
            color: #7f7f7f;
           }
        }
        .description {
           margin-top: 5px !important;
          p {
            font-family: "Open Sans";
            font-size: 1rem;
            color: #7f7f7f;
          }
        }

        .flavors {
          h3 {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

.mobile {
  .product-catalog-content {
    .product-thumb {
      .image-holder {
        display: inline-block;
        width: 100%;
        max-width: 200px;
        width: 100px !important;
        background-size: contain !important;
        height: 100px !important;
        background-position: 50%;
        background-repeat: no-repeat;
      }
    }
    .leader-board {
      .slide {
        min-height: 170px;
        position: relative;

        .slide-banner {
          height: 170px;
        }

        .slide-content {
          left: 25px;
          bottom: 0px;
          transform: translateY(-35%);

          p {
            font-family: "Montserrat";
            font-size: 2rem !important;
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }
    }

    .product-query {
      padding: 16px 24px 0px 24px;
      // background-color: red;
      .ant-col:first-child {
        display: flex;
      }

      .ant-form-item {
        flex: 1;
        margin-bottom: 1rem;

        .ant-form-item-control {
          flex: 1;

          .ant-input {
            font-family: "Open Sans";
            font-size: 1rem;
            color: #707070;
            position: relative;
            padding: 0px 0px 0px 40px;
            border: 1px solid #bfbfbf;
          }
          span.ant-input-suffix {
            position: absolute;
            left: 15px;
            display: inline !important;
            svg {
              font-size: 1rem;
              color: #707070;
            }
            svg:hover,
            svg:focus {
              color: #e30512;
            }
          }
        }
      }

      .product-query-result-text {
        margin: 0;
      }

      .filter-holder {
        border: 1px solid #bfbfbf;
        border-left-style: none;
        padding: 10px;
        height: 40px;
        &:hover {
          opacity: 0.5;
          transition: all 50ms ease-in;
        }
        cursor: pointer;
        img {
          width: 1.1rem;
          height: 1.1rem;
        }
      }

      .mobile-filter-toggle {
        color: $text-primary-color-active;
      }
    }

    .product-result {
      padding: 16px 24px;

      .xs-brand-padding {
        padding-top: 47px !important;
      }

      .loader {
        padding: 45px !important;
      }

      .product:nth-child(even) {
        margin-left: 12px;
      }

      .product {
        width: 48% !important;
        height: 300px;
        flex-basis: 48%;
        flex-grow: 0;
        margin-left: 0px;
        margin-right: 0px;
        border: 1px solid #e0e0e0;
        border-radius: 15px;
        .ant-card-body {
          padding-left: 0px;
          padding-right: 0px;
          // background-color: green;
        }
        h2 {
          font-size: 1rem;
        }
      }
      .slick-dots {
        padding: 10px 0;
      }
    }
  }
}

.filter-options-drawer {
  .ant-drawer-content-wrapper {
    .ant-drawer-wrapper-body {
      background-color: $main-background;

      .ant-drawer-header {
        padding: 0;

        .ant-drawer-title {
          .ant-card {
            .ant-card-head {
              border: none;

              button {
                color: $text-primary-color-active;
              }
            }

            .ant-card-body {
              display: none;
            }
          }
        }
      }

      .ant-drawer-body {
        padding: 0;
        @include filterMenu;

        .ant-menu-submenu-title #text {
          font-size: 0.5rem;
        }

        .ant-menu-item:active,
        .ant-menu-submenu-title:active {
          background-color: transparent;
        }
      }
    }
  }
}

.product-details-modal {
  padding: 0;

  .ant-modal-header {
    padding: 30px 24px;

    .ant-modal-title {
      font-family: "Open Sans";
      font-size: 24px;
      font-weight: bold;
      color: #808080;
    }
  }

  .description, .configs {
    h2 {
      font-weight: 600;
      color: #606060 !important;
      font-size: 16px;
    }
  }

  p {
    font-family: "Open Sans";
  }

  h2,
  h3,
  h4,
  h5 {
    color: $text-primary-color;
  }

  h3 {
    font-size: 0.9rem;
  }

  

  @media only screen and (min-width: 1224px) {
    &::before {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 25px;
      left: -25px;
      top: 0;
      background-color: $strip-background;
      background-image: url("../../assets/images/circle-overlay.png");
      background-size: 25px;
    }
  }

  .ant-modal-content {
    border-radius: 0;

    .ant-modal-body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .product-gallery {
      flex: 1;
      float: none;
      padding-top: 24px;
      padding-bottom: 24px;
      @media only screen and (min-width: 1224px) {
        border-right: 1px solid #e8e8e8;
      }

      .slick-slide {
        .image-holder {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        h2 {
          text-align: center;
          margin: 10px 0;
          font-weight: 700;
        }
      }

      .slick-dots {
        position: static;
        margin-top: 10px;

        li {
          width: 100px;
          height: auto;
          border: 1px solid transparent;
          padding: 5px;

          h3 {
            margin: 0;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &.slick-active {
            border: 1px solid $text-primary-color-active;

            h3 {
              color: $text-primary-color-active;
            }
          }
        }
      }
    }

    .description {
      margin-top: 20px;
       @media only screen and (min-width: 1224px) {
        margin: 0;
      }
    }

    .product-details {
      float: none;
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: 1224px) {
        padding-top: 24px;
      }
      padding-bottom: 24px;
      div:first-child {
        flex: 1;
      }

      .flavors {
        .image-holder {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }

        h3 {
          text-align: center;
          margin: 5px 0;
        }

        .slick-active {
          a.selected {
            h3 {
              color: #7A4521;
            }
          }
        }

        .slick-dots {
          position: static;

          button {
            width: 12px;
            height: 12px;
            border: 1px solid $text-primary-color-active;
            border-radius: 50%;

            &:before {
              display: none;
            }
          }

          li {
            margin: 0;

            &.slick-active {
              button {
                background-color: $text-primary-color-active;
              }
            }
          }
        }
      }
    }
  }
}

// for mobile only

.product-details-modal-row {
  .desc {
    padding-bottom: 10px;
  }
  h2 {
    font-family: "Open Sans";
    font-size: 1.2rem;
    color: #323232; 
  }
  label {
    font-family: "Open Sans";
    font-size: 0.9rem;
    display: block;
    color: #7f7f7f;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    font-family: "Open Sans";
    color: #7f7f7f;

    padding-bottom: 10px;
  }
  span {
    font-family: "Open Sans";
    color: #7f7f7f;
  }
  .available-flav {
    margin-top: 20px;
  }

  .slick-slider {
    .slick-dots {
      background-color: #fff;
      position: relative;
      padding-bottom: 20px;
    }
    .slick-dots:before {
      content: "";
      position: absolute;
      left: -24px;
      width: 50px;
      height: 45.6px;
      background-color: #fff;
    }
    .slick-dots:after {
      content: "";
      position: absolute;
      right: -24px;
      width: 50px;
      height: 45.6px;
      background-color: #fff;
    }
    li button:before {
      border-color: 1px solid #e30512;
      font-size: 10px;
    }

    .slick-active button:before {
      color: #e30512 !important;
      font-size: 10px;
    }
    h3 {
      font-size: 0.8rem !important;
    }
  }
}
