@import '../../assets/styles/variables';

//Banner MediaQuery (Mobile View 520px)
@media only screen and (max-width: 520px) {
  .banner-container {
    color: red;
    position: relative;

    .banner-title {
      padding: 10vh 5vh !important;

      .banner-slider-text {
        h3 {
          font-size: 35px !important;
        }

        h4 {
          font-size: 27px !important;
        }

        p {
          font-size: 15px !important;
        }
      }
    }
  }

  .home-container {
    .slick-dots {
      display: flex;
      justify-content: center;
      align-items: center;
    }
   
    .our-brands-container .slick-slider .slick-dots li {
      margin: 0px;
    }

    .slick-dots li button {
      padding: 0px;
      margin: 0px;
    }
    .slick-dots li button:before  {
        font-size: 0.5rem !important;
     }
  }
  

  
}

//Sass Converted for Banner
.banner-container {
  width: 100%;
  font-family: "montserrat";
  position: relative;

  .banner-title {
    position: absolute;
    // background-color: rgba(00, 00, 00, .4);
    padding: 9vh;
    width: 100%;
    height: 100%;
    z-index: 99999;
    // text-shadow: black 1px 0 17px;

    .banner-slider-text {
      margin-top:90px;
      margin-left: 30px;
      h3 {
        font-family: 'Montserrat';
        font-size: 60px;
        font-weight: 900;
        line-height: 64px;
        color: white;
        margin: 5px;
       }

      h4 {
        font-family: 'Montserrat';
        font-size: 42px;
        font-weight: 200;
        color: white;
        padding: 10px 0 6px 0;
        }

      p {
        -ms-wrap-flow: 10px;
        font-family: 'Open Sans';
        color: white;
        font-size: 16px;
        font-weight: 300;
         padding: 0px 0px 10px 0px;
         max-width: 500px;
       }

      .banner-button-container {

        .banner-read-button {
          font-size: 16px;
          font-weight: 400;
          width: auto;
          height: auto;
          background: #BF914A !important;
          color: white !important;
          padding: 7px 40px;
          margin-top: 10px;
          margin-left: 10px;
          border: 0;
         

          &:hover {
             border: 0;
          }
        }
      }

      .slick-slide img {
        // opacity: 0.8;
      }
     
      .slick-slider
        .react-slick-custom-btn-left,
        .react-slick-custom-btn-right {
           top: 65%;
         }
        .react-slick-custom-btn-right {
          left: 85%;
        }
  
      .slick-slider .slick-dots {
        text-align: left;
        margin-bottom: -19vh;
        bottom: 25px;

        li:first-child  {
         margin-left: 15px;
        }

        li  {
          margin: 0 15px;
          border: 2px solid white;
          border-radius: 50%;
          opacity: 0.5;
          button:before {
            color: transparent;
          }
        }
        .slick-active {
          opacity: 1;
          button:before  {
            font-size: 0.8rem;
             color: white;
             opacity: 1;
             position: absolute !important;
             top: -0.9px;
             left: -2px;
          }
         }
       
      }
    }
  }

  .react-slick-custom-btn-left {
    position: absolute;
    cursor: pointer;
    color: white;
    top: 55%;
    left: -20px;

    .left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }

  .react-slick-custom-btn-right {
    position: absolute;
    cursor: pointer;
    color: red;
    top: 55%;
    right: -20px;

    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }

  i {
    border: solid white;
    border-width: 0px 4px 4px 0px;
    display: inline-block;
     padding: 0.3rem;
  }
}


//Our Brands MediaQuery (Mobile View 520px)
@media only screen and (max-width: 520px) {
  .our-brands-container {
    padding: 5vh 5vh 10vh !important;
    text-align: center;

  
  }
  .slick-slider
  .react-slick-custom-btn-left,
  .react-slick-custom-btn-right {
     top: 50% !important;
   }
   
  .react-slick-custom-btn-right {
    left:100% !important;
  }
}

// Our Brands
.our-brands-container {
  padding: 10vh;

  font-family: "montserrat";

  h1 {
    font-weight: 900;
    color: #323232;
    font-size: 42px;
  }

  p {
    font-family: 'Open Sans';
    font-size: 16px;
    color: #404040;
   }

  .product-slider {

    .product-display-overlay {
      background: linear-gradient(to left, #FFFFFF, rgba(#FFFFFF, 0), #FFFFFF);
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 0.9;
      pointer-events: none;
    }

    img {
      width: 100px;
    }
  }

  .view-brands-link {
    padding: 15px 0;

    span a {
      font-family: 'Open Sans';
      font-size: 18px;
      font-weight: bold;
      color: #E30612;
      padding: 10px 0;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .slick-slider .slick-dots {
    margin-bottom: -2rem;

    li {
      margin: 0 5px;

      button:before {
        font-size: 1rem;
        width: 25px;
        height: 25px;
        color: red;
        opacity: none;
        line-height: 23px;
      }
    }
  }
}


//Sell Product MediaQuery (Mobile View 520px)
@media only screen and (max-width: 520px) {
  .sell-product-container .sell-item-product-slider .react-slick-custom-btn-right {
    display: none;
  }
}

//Sell Product
.sell-product-container {
  background: #FDFBF8;
  width: 100%;
  font-family: "montserrat";
  background-image: url('../../assets/images/home-banners/circle-pink.png');
  background-position: center center;
  background-repeat: no-repeat;

  .sell-header-title {
    padding: 1vw;

    h1 {
      color: #323232;
      font-weight: 900;
      padding: 25px 5px 5px;
      font-size: 42px;
    }

    p {
      padding: 5px;
      font-size: 18px;
    }

    .view-catalog {
      padding: 10px 0;
      
      a {
        text-decoration: underline;
        color: #E6242E;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .sell-item-product-slider {
    padding: 1vh 6vw;

    .sell-item-container {
      display: flex !important;
      justify-content: center;

      .sell-item-card {
        background-color: #F4EBDE;
        width: 300px !important;
        height: 470px;
        border-radius: 5px;
        display: inline-block;
        margin: 30px 10px;
        cursor: pointer;
        transition: all 0.8s ease;

        .image-container {
          display: flex;
          justify-content: center;
          background: red;
          height: 150px;
          margin: 25px 25px 0;
          background: no-repeat;
          background-image: url('../../assets/images/dot-background-repeat.svg');

          img {
            //Comentted for now
            // height: 150px;

            //adjusted the css for now
            height: 210px;
            margin-top: -35px;
          }
        }

        &:hover {
          background: #A80F1B;

          .sell-item-title {
            color: white;
          }

          .sell-item-info {
            color: #F4EBDE;
          }

          .sell-item-beverages {
            color: white;
          }
        }
      }
    }

    .sell-item-description {
      padding: 15px;

      .sell-item-title {
        color: #80796F;
        font-weight: 600;
        font-size: 19px;
      }

      .sell-item-info {
        margin: 20px 0;
        font-size: 16px;
        color: #80796F;
      }

      .sell-item-beverages {
        color: #8D5F3E;
        font-weight: bold;
        position: absolute;
        bottom: 8%;
      }
    }

    .react-slick-custom-btn-left {
      position: absolute;
      cursor: pointer;
      color: red;
      top: 50%;
      left: -50px;

      .left {
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
    }

    .react-slick-custom-btn-right {
      position: absolute;
      cursor: pointer;
      color: red;
      top: 50%;
      right: -60px;

      .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }

    i {
      border: solid red;
      border-width: 0px 7px 7px 0px;
      display: inline-block;
      padding: 0.5rem;
    }
  }
}

//Whats new
.whats-new-container {
  padding: 1vw 12vh;
  font-family: "montserrat";

  .whats-new-card-first {
    margin: 100px 0;
    background: #A80F1B;
    width: 395px !important;
    height: 485px;

    .whats-new-card-first-title {
      padding: 50px 30px;
      text-align: center;
      background-image: url('../../assets/images/dot-background-repeat.svg');

      span {
        font-size: 2.5rem;
        color: white;
        font-weight: bold;
      }
    }

    .check-update-description {
      padding: 30px;
      text-align: center;

      span {
        font-size: 1.3rem;
        color: white;
        opacity: 0.9;
      }
    }

    .check-what-news {
      padding: 15px;
      text-align: center;

      span {
        font-size: 20px;
        font-weight: bold;
        color: white;
      }

      .check-news-button {
        background: #BF914A !important;
        color: white !important;
        height: 70px !important;
        width: 250px;
      }
    }
  }

  .whats-new-card {
    background: white;
    /* border: 1px solid black; */
    margin: 100px 0;
    width: 310px !important;
    display: inline-block;
    position: relative;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(0);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);

    &:hover {
      box-shadow: 0px 0px 10px #000000;
      z-index: 999999;
      -webkit-transition: all 200ms ease-in;
      -webkit-transform: scale(1.5);
      -ms-transition: all 200ms ease-in;
      -ms-transform: scale(1.5);
      -moz-transition: all 200ms ease-in;
      -moz-transform: scale(1.5);
      transition: all 200ms ease-in;
      transform: scale(1.1);
    }

    .image-wrapper img {
      height: 200px;
      width: -webkit-fill-available;
    }
  }

  .whats-text-content {
    box-shadow: 0 4px 6px 2px #F5F5F5;

    .whats-new-title {
      padding: 15px;
    }

    .whats-new-horizontal {
      padding: 0 10px;

      hr {
        border: 1px solid #F4EBDE;
      }
    }

    .whats-new-description {
      padding: 15px;

      span {
        font-size: 15px;
      }
    }

    .whats-new-view-details {
      padding: 15px 15px 30px;

      span {
        color: red;
        font-size: 15px;
      }
    }
  }

  .react-slick-custom-btn-left {
    position: absolute;
    cursor: pointer;
    color: red;
    top: 50%;
    left: -50px;

    .left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }

  .react-slick-custom-btn-right {
    position: absolute;
    cursor: pointer;
    color: red;
    top: 50%;
    right: -60px;

    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }

  i {
    border: solid red;
    border-width: 0px 7px 7px 0px;
    display: inline-block;
    padding: 0.5rem;
  }
}


//Contact Us MediaQuery (Mobile View 520px)
@media only screen and (max-width: 520px) {
  .contact-us-container {
    .contact-message {
      h1 {
        font-size: 30px !important;
      }

      p {
        text-align: justify;
      }
    }
  }
}


// Contact Us
.contact-us-container {
  background: #A80F1B;
  font-family: "montserrat";

  .contact-message-container {
    background-image: url('../../assets/images/dot-rectangular.png');
    /* background-repeat: no-repeat; */

    .contact-message {
      font-family: 'Montserrat';
       padding: 20px 6vw;
   

      h1 {
        color: white;
        font-size: 42px;
        font-weight: 900;
      }

      p {
      font-family: 'Open Sans';
        color: white;
        font-size: 16px;
       }

      .ant-btn,
      button {
        border: 1px solid white;
        padding: 10px 60px;
        margin: 20px 0;
        border-radius: 46px;
        background: transparent;
        height: auto;
        color: white;
        font-weight: 600;
        font-size: 18px;


        &:hover {
          border: 1px solid gray;
          // box-shadow: 0px 0px 4px 1px white;
          background: #FCF2EF;
          color: black;
          font-weight: bold;
        }

      }
    }
  }

  .contact-us-button-container {
    background-image: url(../../assets/images/home-banners/world-map.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 300px;
  }

  @media only screen and (max-width: 991px) {
    .contact-us-button-container {
      min-height: 0;
    }
  }
}