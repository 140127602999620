@import './variables';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif, "Open Sans", "Montserrat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .ant-layout{
    background: none;
  }

  .ant-input, .ant-select > div, .ant-select-dropdown, .ant-input-number{
      border-radius: 0 !important;
  }

  .ant-modal-confirm-body {
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-gap: 10px;

    .ant-modal-confirm-content {
      grid-column-start: 2;
      margin: 0 !important;
    }

    .ant-modal-confirm-content:first-letter {
      text-transform: capitalize
    }

    img {
      width: 50px;
      height: 50px;
    }

    .anticon {
      font-size: 45px;
      margin: 0 !important;
    }
  }  
  
  .ant-modal-confirm-btns {
    .ant-btn-primary {
      width: 80px;
      height: 42px;
      font-size: 16px;
      border-radius: 46px;
      color: #fff;
      border: 1px solid #bf914a !important;
      background: #bf914a !important;
    }
  }

  button{
    font-family: "Open Sans";
    &.ant-btn{
      border-radius: 46px;
      font-weight: 600;
      box-shadow: none;
    }
    &.btn-main {
      height: 50px;
      min-width: 172px;
      font-size: 18px;
      line-height: 25px;
    }

    &.btn-small {
      height: 42px;
      min-width: 150px;
      font-size: 0.8rem;
      line-height: 22px;
    }

    &.btn-default {
      color: #7A4521;
      border: none;
      &:hover, &:focus {
        color: #7A4521;
        border: none;
        background: transparent;
      }
      &:disabled, &:disabled:hover {
        background: transparent;
      }
    }

    &.btn-secondary {
      color: #7A4521;
      border: 1px solid #7A4521; 
      &:hover, &:focus {
        color: #7A4521;
        border: 1px solid #7A4521; 
        background: transparent;
      }
      &:disabled, &:disabled:hover {
        background: transparent;
      }
    }

    &.btn-secondary-gold {
      color: #7A4521;
      border: 1px solid #7A4521; 
      &:hover, &:focus {
        color: #7A4521;
        border: 1px solid #7A4521; 
        background: transparent;
      }
      &:disabled, &:disabled:hover {
        background: transparent;
      }
    }

    &.btn-primary {
      background-color: $button-primary-color;
      border-color: $button-primary-color;
      color: #FFFFFF;
      &:hover, &:active, &:focus {
        background-color: $button-primary-color;
        border-color: $button-primary-color;
        color: #FFFFFF;

      }
      &:disabled, &:disabled:hover {
        background-color: #E5D3B6;
        border-color: #E5D3B6;
        color: #FFFFFF;
      }
    }

    .has-error {
      border-color: #f5222d !important;
      box-shadow: none !important;
    }

    .ant-input {
      border-bottom: 2px solid rgba(191,145,74, .49);
    }

  }

  @import url('https://fonts.googleapis.com/css?family=Montserrat:thin,extra-light,light,100,200,300,400,500,600,700,800');
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:thin,extra-light,light,100,200,300,400,500,600,700,800');
  @import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css')
  // @import '~slick-carousel/slick/slick';
  // @import '~slick-carousel/slick/slick-theme';