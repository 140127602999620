@import "../../assets/styles/variables";

@mixin product-information {
  display: flex;
  align-items: center;

  .product-image {
    display: inline-block;
    width: 70px;
    height: 70px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .product-id {
    flex: 1;
    margin-left: 12px;

    h5 {
      color: gray;
      margin: 0;
    }

    h3 {
      margin: 0;
    }
  }
}

.product-order-content {
  padding: 50px;
  font-family: "montserrat";
  overflow: hidden;
  position: relative;

  .product-order-form {
    .ant-card {
      border-color: rgba(0, 0, 0, 0.09);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);

      .ant-card-body {
        padding: 0;
      }
    }
    .po-form-header {
      display: flex;
      flex-flow: row wrap;
      justify-content: start;
      align-items: center;
      background-color: $strip-background;
      padding: 24px;
      h2 {
        font-family: "Open Sans";
        color: #fff;
        margin: 0;
        font-size: 28px;
        line-height: 38px;
      }
    }

    .product-order-query-form {
      padding: 24px;

      .ant-form-item {
        display: flex;
        margin: 0;

        .ant-form-item-control-wrapper {
          flex: 1;
        }
      }
    }

    .product-order-result {
      .product-table {
        margin: 20px 0;
        padding: 0 15px;

        .table-header {
          padding: 10px 20px;
          color: #848484;
          font-family: "Open Sans";

          .order-date {
            font-size: 20px;
            font-weight: 400;
          }

          .order-number {
            font-size: 20px;
            font-weight: 500;
          }

          .separator {
            font-size: 0.5rem;
            margin: 5px 10px;
            padding-bottom: 5px;
          }

          .product-order-length {
            font-size: 20px;
            font-weight: 300;
          }

          .cancelled-flex {
            display: flex;
            flex-direction: row-reverse;
            width: 100%;

            .cancelled-flex-content{
              background-color: #FEF3F3;
              padding: 10px 15px;
              border: 1px solid #F5A2A6;
              border-radius: 20px;

              span {
                color:#A80F1B;
                font-weight:bold;
              }
            }
          }
        }

        .order-again {
          color: #a80f1b;
          font-size: 1rem;
        }

        .table-holder {
          background-color: #ffffff;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
          padding: 0 !important;
          border-top: 1px solid rgba(0, 0, 0, 0.09);

          .product-table {
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
            padding: 0;
            margin: 0;
          }

          .toggle-button:hover {
            cursor: pointer;
            color: #a80f1b;
          }

          .order-history-footer {
            background: #fff;
            padding: 20px;

            > div:nth-child(2) {
              > div > div label {
                font-size: 0.7rem;
              }
              > div > div span {
                font-size: 0.9rem;
                font-weight: bold;
              }
            }
          }

          //   .footer {
          //     padding: 30px 20px;
          //     background-color: #ffffff;

          //     &-col {
          //       padding-bottom: 10px;
          //     }

          //     &-label {
          //       font-size: 0.7rem;
          //       margin-right: 10px;
          //     }

          //     &-value {
          //       font-size: 0.9rem;
          //       font-weight: bold;
          //     }
          //   }
        }
      }

      .ant-list-bordered {
        border-top-width: 0;
        border-radius: 0;
        border-color: $secondary-border;

        .ant-list-header {
          font-family: "Open Sans";
          font-size: 12px;
          margin: 0 -1px;
          color: #999999;
          background-color: $strip-background-secondary;

          .ant-row {
            h3 {
              margin: 0;
              text-transform: uppercase;
              color: $text-primary-color;
              font-size: 0.8rem;
            }
          }
        }

        .ant-list-footer {
          padding: 0;

          .ant-row {
            margin: 0 !important;
          }

          .footer-details {
            h3 {
              font-family: "Open Sans";
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 22px;
              color: #999999;
            }

            p {
              font-family: "Montserrat";
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 20px;
              color: #404040;
            }
          }

          .totals {
            padding: 24px;
            border-bottom: 1px solid $secondary-border;
            background-color: $strip-background;

            h3 {
              margin: 0;
              color: #ffffff;
            }

            h5 {
              margin: 0;
              color: #ffffff;
            }
          }

          .actions {
            padding: 24px;
            text-align: center;

            button {
              margin: 0 6px;
            }
          }
        }

        .ant-spin-container {
          max-height: 800px;
          overflow: auto;
        }

        .ant-list-items {
          .ant-list-item.product {
            border-color: $secondary-border;

            .ant-row-flex {
              flex: 1;

              .ant-col {
                h5 {
                  color: $text-primary-color;
                  margin: 0;
                }

                h3 {
                  color: $text-primary-color;
                  margin: 0;
                  text-transform: capitalize;
                }

                h4 {
                  color: $text-primary-color-active;
                  text-transform: uppercase;
                }

                h5 {
                  color: $text-primary-color;
                  text-transform: uppercase;
                }

                &.product-information {
                  @include product-information;
                }

                .ant-form-item {
                  margin: 0;
                }
              }
            }
          }

          .product-information-mobile {
            width: 100%;
            @include product-information;

            .product-details {
              flex: 1;

              & > div {
                display: flex;
                margin-left: 15px;
                align-items: center;

                .product-id {
                  margin: 0;
                  margin-right: 15px;
                }

                &:last-child {
                  span {
                    flex: 1;
                    text-align: center;

                    &:first-child {
                      text-align: left;
                    }

                    &:last-child {
                      text-align: right;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .po-history-sub-header {
        > div:first-child {
          padding-bottom: 5px;
          font-family: "Montserrat";
          font-size: 1rem;
          color: #848484;
        }
        > div:last-child {
          label {
            font-family: "Open Sans";
            font-size: 0.7rem;
            color: #a8a8a8;
            display: block;
            padding: 5px 0px;
          }
          span {
            font-size: 0.8rem;
            color: #4a4a4a;
          }
        }
      }
    }
  }

  .recent-order-status {
    margin-top: 14px;
    .inprocess{
      border:1px solid #FFC590;
      border-radius: 20px;
      background-color: #FFEFE0;
      padding: 10px 20px;
      font-weight: bold;
      color:#D66F0E;
      font-size: 12px;
    }
    .cancelled{
      border:1px solid #FFBEC2;
      border-radius: 20px;
      background-color: #FEF3F3;
      padding: 10px 20px;
      font-weight: bold;
      color:#A80F1B;
      font-size: 12px;
    }
  }
}

.mobile {
  .product-order-content {
    padding: 15px;
    position: relative;

    .ant-card {
      padding: 0px !important;
    }
  }

  .product-order-result {
    .ant-form {
      padding: 0;

      .ant-list-header {
        display: none;
      }
    }
  }

  .xs-order-history-content {
    border: 0.5px solid #e5e5e5;
    // box-shadow: 1px 2px 4px #00000017;

    > div {
      padding: 20px;
      > div > div {
        padding: 10px 0px;
      }

      label {
        display: block;
        font-family: "Open Sans";
        font-size: 0.8rem;
        color: #999999;
        text-transform: uppercase;
      }
      span {
        font-family: "Montserrat";
        font-size: 0.9rem;
        color: #404040;
      }
    }
  }

  .xs-order-history-footer {
    //
    > div {
      > div > div {
        padding: 10px 0px;
      }
      label {
        display: block;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #999999;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
      span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #404040;
      }
    }
  }
  .xs-expand {
    padding: 20px 2px 20px 10px;
    border: 0.5px solid #e5e5e5;
    font-family: "Montserrat";
    > div {
      label {
        display: block;
        text-transform: uppercase;
        color: #656565;
        padding-bottom: 5px;
        font-size: 0.8rem;
      }
      span {
        display: block;
        font-size: 0.9rem;
        font-weight: 600;
        color: #323232;
      }
    }
  }
  .product-order-form .recent-search-holder {
    background-color: #fff;
    padding: 20px 0px;
  }
}

// search related
.search-result-indicator {
  font-family: "Open Sans";
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  padding: 20px 0;
}

.product-order-form .recent-search-holder {
  background-color: #fff;
  padding: 20px 25px;
}
